var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reports" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "Analytics",
          buttonText: "New Order",
          buttonIconClass: "icon-add",
          buttonLink: "/new"
        }
      }),
      _c(
        "div",
        { staticClass: "container-fluid pt-64 pt-md-80 pb-80 pb-md-0" },
        [
          _c("div", { staticClass: "row py-16" }, [
            _c(
              "div",
              {
                staticClass: "col d-flex align-items-center justify-content-end"
              },
              [
                _c(
                  "div",
                  { staticClass: "date-wrap mr-0" },
                  [
                    _c("multiselect", {
                      staticClass: "dateRange",
                      attrs: {
                        id: "channel",
                        options: [
                          "7 Days",
                          "30 Days",
                          "Last Month",
                          "This Quarter"
                        ],
                        searchable: false,
                        "show-labels": false,
                        placeholder: "Select Range"
                      },
                      model: {
                        value: _vm.dateRange,
                        callback: function($$v) {
                          _vm.dateRange = $$v
                        },
                        expression: "dateRange"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ]),
          _c("div", { staticClass: "row pb-8" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "card card--report" }, [
                _c(
                  "div",
                  { staticClass: "card__content" },
                  [
                    _vm.isBusy
                      ? _c(
                          "div",
                          { staticClass: "chart_height" },
                          [
                            _c("b-spinner", {
                              staticClass: "text-gray-400 m-auto"
                            })
                          ],
                          1
                        )
                      : _c("StackedColumnChart", {
                          attrs: { data: _vm.orderTypeChart }
                        })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "card card--report" }, [
                _c(
                  "div",
                  { staticClass: "card__content" },
                  [
                    _vm.isBusy
                      ? _c(
                          "div",
                          { staticClass: "chart_height" },
                          [
                            _c("b-spinner", {
                              staticClass: "text-gray-400 m-auto"
                            })
                          ],
                          1
                        )
                      : _c("StackedColumnChart", {
                          attrs: { data: _vm.modeTypeChart }
                        })
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row py-8" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "card card--report" }, [
                _c(
                  "div",
                  { staticClass: "card__content" },
                  [
                    _vm.isBusy
                      ? _c(
                          "div",
                          { staticClass: "chart_height" },
                          [
                            _c("b-spinner", {
                              staticClass: "text-gray-400 m-auto"
                            })
                          ],
                          1
                        )
                      : _c("StackedColumnChart", {
                          attrs: { data: _vm.carriersByModesBarData }
                        })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "card card--report" }, [
                _c(
                  "div",
                  { staticClass: "card__content" },
                  [
                    _vm.isBusy
                      ? _c(
                          "div",
                          { staticClass: "chart_height" },
                          [
                            _c("b-spinner", {
                              staticClass: "text-gray-400 m-auto"
                            })
                          ],
                          1
                        )
                      : _c("StackedColumnChart", {
                          attrs: { data: _vm.zonesByCarriersBarData }
                        })
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row py-8" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card__content" },
                  [
                    _vm.isBusy
                      ? _c(
                          "div",
                          { staticClass: "chart_height" },
                          [
                            _c("b-spinner", {
                              staticClass: "text-gray-400 m-auto"
                            })
                          ],
                          1
                        )
                      : _c("LineChart", {
                          attrs: { data: _vm.carrierTypeChart }
                        })
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("TabularReports", {
                  ref: "TabularReports",
                  attrs: { firstDay: _vm.firstDay, lastDay: _vm.lastDay }
                })
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }