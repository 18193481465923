<template>
  <highcharts
    :data="data"
    :options="stackedColOptions"
    :updateArgs="updateArgs"
  ></highcharts>
</template>

<script>
export default {
  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }],
      stackedColOptions: {
        chart: {
          minHeight: this.data.height.minHeight,
          maxHeight: this.data.height.maxHeight,
          type: 'line',
          style: {
            fontFamily: '"Roboto", sans-serif',
          },
        },
        title: {
          text: this.data.title,
          align: "left",
          style: {
            fontSize: "20px",
            lineHeight: "20px",
            color: "#2d374b",
            fontWeight: "500",
          },
        },
        xAxis: {
          categories: this.data.categories,
          labels: {
            style: {
              color: "#828b9d",
              fontSize: "12px",
              lineHeight: "13px",
            },
          },
        },
        yAxis: {
          min: 0,
          tickInterval: 5,
          floor: 0,
          gridLineColor: "#e3e7ee",
          title: "",
          labels: {
            style: {
              color: "#828b9d",
              fontSize: "12px",
              lineHeight: "13px",
            },
          },
          stackLabels: {
            enabled: false,
            style: {
              fontWeight: "bold",
              color: "gray",
            },
          },
        },
        legend: {
          reversed: true,
          align: "left",
          verticalAlign: "top",
          layout: "horizontal",
          itemMarginTop: 0,
          itemMarginBottom: 30,
          itemStyle: {
            fontSize: "14px",
            lineHeight: "24px",
            color: "#545f75",
            fontWeight: "normal",
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:16px; font-weight:500; color:#fff;>{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td> </tr></br>',
          footerFormat: "</table>",
          borderColor: "#2d374b",
          backgroundColor: "#2d374b",
          borderRadius: 10,
          color: "#fff",
          fontSize: "20px",
          lineHeight: "20px",
          shared: false,
          useHTML: true,
        },
        plotOptions: {
          column: {
            stacking: "normal",
            column: {
              pointPadding: 0.2,
              borderWidth: 0,
              borderRadius: 6,
            },
          },
          series: {
            maxPointWidth: 31,
            borderRadius: 6,
          },
        },
        credits: {
          enabled: false,
        },
        series: this.data.chartData
      },
    };
  },
  props: {
    data: Object
  },
};
</script>
