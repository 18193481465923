<template>
	<div>
		<div class="row py-8">
			<div class="col-md-12">
				<div class="card">
					<div class="card__title align-items-center">
						<span class="fs18 fw500  d-block"> Tabular Chart </span>
					</div>
					<div class="card__content overflow-visible">
						<div class="row mb-10">
							<div class="col-lg-2 col-sm-3">
								<multiselect
								id="channel"
								v-model="chartType.one"
								:options="options"
								:searchable="false"
								:show-labels="false"
								label="name"
								@input="getItems"
								placeholder="Select Chart"
								class=""
								></multiselect>
							</div>
							<div class="col-lg-2 col-sm-3">
								<!-- <multiselect
								id="channel"
								v-model="chartType.two"
								:options="options"
								:searchable="false"
								:show-labels="false"
								label="name"
								@input="getItems"
								placeholder="Select Chart"
								class=""
								></multiselect> -->
							</div>
							<div class="col-lg-2 col-sm-3">
								<!-- <multiselect
								id="channel"
								v-model="chartType.three"
								:options="options"
								:searchable="false"
								:show-labels="false"
								label="name"
								@input="getItems"
								placeholder="Select Chart"
								class=""
								></multiselect> -->
							</div>
							<div class="col-lg-6 col-sm-3 mt-10">
								<div class="float-right">
									<b-form-checkbox v-model="percentage" class="d-inline mr-20 ml-20" switch></b-form-checkbox> 
									Percentage (%)
								</div>
							</div>
						</div>
						<div v-if="isBusy" class="chart_height">
							<b-spinner class="text-gray-400 m-auto"></b-spinner>
						</div>
						<div v-else>
							<div class="table-responsive" v-if="tabularData.dim==2">
								<table class="table table-bordered ">
									<thead>
										<tr>
											<th class="bg-gray-100">Status</th>
											<th v-for="cols, index in tabularData.status" class="bg-gray-100" :key="index">{{ index }}</th>
											<th class="bg-gray-100">Total</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="row, r in tabularData.rows" :key="r">
											<th class="bg-gray-100">{{ row | capitalize }}</th>
											<td v-for="cols, c in tabularData.status" :key="c">
												<span v-if="percentage">{{ cols[chartType.one.value][row]?Math.round((cols[chartType.one.value][row].total/tabularData[row])*100 * 100)/100+'%':'-' }}</span>
												<span v-else>{{ cols[chartType.one.value][row]?cols[chartType.one.value][row].total:'-' }}</span>
											</td>
											<td>{{tabularData[row]}}</td>
										</tr>
										<tr>
											<th class="bg-gray-100">Total</th>

											<!-- Sub Totals -->
											<td v-for="cols, c in tabularData.status" :key="c">{{ cols.total }}</td>

											<!-- Total -->
											<td>{{tabularData.total}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
	name: 'TabularReports',
	props: ['firstDay', 'lastDay'],
	components: { Multiselect },
	data() {
		return {
			isBusy: false,
			options: [{name:'Carrier', value: 'carrierID'}, {name:'Zone', value:'zone'}, {name:'State', value:'state'}, {name:'Type', value:'type'}, {name:'Channel', value:'channel'}, {name:'Mode', value:'mode'}],
			chartType: {one:{name:'Type', value: 'type'}},
			tabularData: {},
			percentage: false,
		}
	},
	created() {
		this.getItems()
	},
	methods: {
		async getItems() {
			const postdata = {
				"from": this.firstDay,
				"to": this.lastDay,
				"datamap": Object.values(this.chartType).map(i => i.value)
			}
			postdata.datamap.unshift('status')
			try {
				this.isBusy = true
				const res = await this.axios.post('/v3/dashboard/tables', postdata)
				if(res.data.success == true) {
					this.tabularData = res.data.data
					let rows = []
					let total = 0
					for(let key in this.tabularData.status) {
						rows = [...rows,...Object.keys(this.tabularData.status[key][this.chartType.one.value])]
					}

					if(!rows.length) {
						this.isBusy = false
						return false;
					}
					
					this.tabularData.rows = new Set(rows)
					this.tabularData.dim = Object.values(this.chartType).length+1
					for(let row of this.tabularData.rows) {
						this.tabularData[row] = 0
						let sum = 0
						for(let key in this.tabularData.status) {
							sum += this.tabularData.status[key][this.chartType.one.value][row]?this.tabularData.status[key][this.chartType.one.value][row].total:0
						}
						total += sum
						this.tabularData[row] = sum
					}
					this.tabularData.total = total
				}
			} catch(e) {
				console.error(e);
			}
			this.isBusy = false
		}
	},
	watch: {
		firstDay(newValue) {
			this.getItems()
		}
	}
}
</script>