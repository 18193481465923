<template>
	<div class="reports">
		<AppTopbar
		title="Analytics"
		buttonText="New Order"
		buttonIconClass="icon-add"
		buttonLink="/new"
		/>

		<div class="container-fluid pt-64 pt-md-80 pb-80 pb-md-0">
			<div class="row py-16">
				<div class="col d-flex align-items-center justify-content-end">
					<div class="date-wrap mr-0">
					<multiselect
					id="channel"
					v-model="dateRange"
					:options="['7 Days', '30 Days', 'Last Month', 'This Quarter']"
					:searchable="false"
					:show-labels="false"
					placeholder="Select Range"
					class="dateRange"
					></multiselect>
				</div>
			</div>
		</div>

		<div class="row pb-8">
			<div class="col-md-6">
				<div class="card card--report">
					<div class="card__content">
						<div v-if="isBusy" class="chart_height">
							<b-spinner class="text-gray-400 m-auto"></b-spinner>
						</div>
						<!-- <DonutChart v-else :data="statusDonutData" type="bar" /> -->
						<StackedColumnChart v-else :data="orderTypeChart" />
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="card card--report">
					<div class="card__content">
						<div v-if="isBusy" class="chart_height">
							<b-spinner class="text-gray-400 m-auto"></b-spinner>
						</div>
						<StackedColumnChart v-else :data="modeTypeChart" />
					</div>
				</div>
			</div>
		</div>

		<div class="row py-8">
			<div class="col-md-6">
				<div class="card card--report">
					<div class="card__content">
						<div v-if="isBusy" class="chart_height">
							<b-spinner class="text-gray-400 m-auto"></b-spinner>
						</div>
						<StackedColumnChart v-else :data="carriersByModesBarData" />
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="card card--report">
					<div class="card__content">
						<div v-if="isBusy" class="chart_height">
							<b-spinner class="text-gray-400 m-auto"></b-spinner>
						</div>
						<StackedColumnChart v-else :data="zonesByCarriersBarData" />
					</div>
				</div>
			</div>
		</div>

		<div class="row py-8">
			<div class="col-md-12">
				<div class="card">
					<div class="card__content">
						<div v-if="isBusy" class="chart_height">
							<b-spinner class="text-gray-400 m-auto"></b-spinner>
						</div>
						<LineChart v-else :data="carrierTypeChart" />
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<TabularReports ref="TabularReports" :firstDay="firstDay" :lastDay="lastDay" />
			</div>
		</div>
	</div>
</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import AppTopbar from "@/components/layout/AppTopbar.vue";
import DonutChart from "@/components/charts/DonutChart.vue";
import ColumnChart from "@/components/charts/ColumnChart.vue";
import LineChart from "@/components/charts/LineChart.vue";
import StackedBarChart from "@/components/charts/StackedBarChart.vue";
import StackedColumnChart from "@/components/charts/StackedColumnChart.vue";
import TabularReports from '@//views/analytics/TabularReports.vue';

const date = new Date()
const firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30)

export default {
	name: "Analytics",
	title: "Shyplite - Analytics",
	data() {
		return {
			isBusy: false,
			firstDay: firstDay,
			lastDay: date,
			dateRange: null,
			data: this.$store.getters.chartdata,
			orderTypeChart: {
				id: "OrderType",
				title: "Order Type",
				height: {minHeight: "240px", maxHeight: "240px"},
			},
			modeTypeChart: {
				id: "ModeType",
				title: "Mode Type",
				height: {minHeight: "240px", maxHeight: "240px"},
			},
			carrierTypeChart: {
				id: "CarrierType",
				title: "Carrier Type",
				height: {minHeight: "240px", maxHeight: "240px"},
			},
			zoneTypeChart: {
				id: "ZoneType",
				title: "Zone Type",
				height: {minHeight: "240px", maxHeight: "240px"},
			},
			orderDonutChart: {
				id: "orderDonut",
				title: "Order Type",
				height: {minHeight: "240px", maxHeight: "240px"},
			},
			modeDonutChart: {
				id: "modeDonut",
				title: "Mode Type",
				height: {minHeight: "240px", maxHeight: "240px"},
			},
			carriersByModesBarData: {
				id: "carriersByModesBarData",
				title: "Carriers Modes",
				height: {minHeight: "240px", maxHeight: "240px"},
			},
			zonesByCarriersBarData: {
				id: "zonesByCarriersBarData",
				title: "Zones Carriers",
				height: {minHeight: "240px", maxHeight: "240px"},
			},
			statusDonutData: {
				id: "statusDonutChart",
				title: "Status Type",
				height: {minHeight: "240px", maxHeight: "240px"},
			},
			stateColumnData: {
				id: "stateColumnChart",
				title: "State Wise",
				height: {minHeight: "240px", maxHeight: "240px"},
			},
		};
	},
	components: {
		AppTopbar,
		Multiselect,
		DonutChart,
		LineChart,
		ColumnChart,
		StackedBarChart,
		StackedColumnChart,
		TabularReports,
	},
	computed: {
		dateSpan() {
			return (this.dateRange[0] | date) + " - " + (this.dateRange[1] | date);
		}
	},
	created() {
		const now = Math.floor(Date.now()/1000)
		if(!this.$store.getters.user.chart_timestamp || this.$store.getters.user.chart_timestamp < now) {
			this.$store.commit('setUser', {chart_timestamp: now + 3*3600})
			this.getItems()
		}
		this.allCharts()
	},
	methods: {
		allCharts() {
			this.ordersType()
			this.modesType()
			this.carriersType()
			this.zonesType()
			this.ordersDonut()
			this.modesDonut()
			this.carriersByModesBarChart()
			this.zonesByCarriersBarChart()
			this.statusDonutChart()
			this.stateColumnChart()
		},
		disabledDates(date) {
			// return date > new Date() || date < new Date(Date.now() + 432000)
			return date < new Date() || date > new Date(new Date().getTime() + 7 * 24 * 3600 * 1000);
		},
		ordersType() {
			let prepaid = [], cod = [], reverse = [], categories = []
			let i = 0
			for(let item in this.data) {
				if(this.data[item].type) {
					categories[i] = new Date(parseInt(item)).toLocaleDateString('en-IN', { month: '2-digit', day: '2-digit' })
					prepaid[i] = this.data[item].type.prepaid?this.data[item].type.prepaid.total:null
					cod[i] = this.data[item].type.cod?this.data[item].type.cod.total:null
					reverse[i] = this.data[item].type.reverse?this.data[item].type.reverse.total:''
					i++
				}
			}
			this.orderTypeChart.categories = categories
			this.orderTypeChart.chartData = [
			{ name: "Reverse", data: reverse, color: "#fa5a5a"},
			{ name: "COD", data: cod, color: "#64d778"},
			{ name: "Prepaid", data: prepaid, color: "#006eff"},
			]
		},

		modesType() {

			let mtypes = {1:[], 2:[], 3:[], 8:[], 9:[], 10:[], 20:[]}, categories = []
			let i = 0
			for(let item in this.data) {
				categories[i] = new Date(parseInt(item)).toLocaleDateString('en-IN', { month: '2-digit', day: '2-digit' })
				for(let m in mtypes) {
					if(this.data[item].mode) {
						mtypes[m][i] = this.data[item].mode[m]?this.data[item].mode[m].total:null
					}
				}
				i++
			}
			this.modeTypeChart.categories = categories
			this.modeTypeChart.chartData = [
			{
				name: "Surface-30kg",
				data: mtypes[20],
				color: "#ff8246"
			},
			{
				name: "Surface-10kg",
				data: mtypes[2],
				color: "#fa5a5a",
			},
			{
				name: "Surface-5kg",
				data: mtypes[10],
				color: "#a064e6",
			},
			{
				name: "Lite-2kg",
				data: mtypes[3],
				color: "#006eff",
			},
			{
				name: "Lite-1kg",
				data: mtypes[8],
				color: "#ffcd3c",
			},
			{
				name: "Lite-0.5kg",
				data: mtypes[9],
				color: "#baf7ba",
			},
			{
				name: "Air",
				data: mtypes[1],
				color: "#64d778",
			}
			]
		},

		stateColumnChart(data) {
			let states = [], obj = [], i = 0
			for(let item in this.data) {
				for(let s in this.data[item].state) {
					if(states.indexOf(s) === -1) {
						states.push(s)
						obj.push(0)
						i++
					}
					obj[i-1] += this.data[item].state[s]?this.data[item].state[s].total:0
				}
			}
			this.stateColumnData.categories = states
			this.stateColumnData.chartData = [
			{
				name: "Total",
				data: obj,
				color: "#64d778",
			}
			]
		},

		carriersType(data) {
			let mtypes = {2:[],3:[],4:[],11:[],6:[],8:[],30:[],7:[],28:[],21:[]}
			let categories = []
			let i = 0
			for(let item in this.data) {
				categories.push(new Date(parseInt(item)).toLocaleDateString('en-IN', { month: '2-digit', day: '2-digit' }))
				for(let m in mtypes) {
					if(this.data[item].carrierID) {
						mtypes[m][i] = this.data[item].carrierID[m]?this.data[item].carrierID[m].total:0
					}
				}
				i++
			}
			this.carrierTypeChart.categories = categories
			this.carrierTypeChart.chartData = [
			{
				name: "Delhivery",
				data: mtypes[2],
				color: "#64d778",
			},
			{
				name: "EcomExpress",
				data: mtypes[3],
				color: "#fa5a5a",
			},
			{
				name: "Fedex",
				data: mtypes[4],
				color: "#a064e6",
			},
			{
				name: "Xpressbees",
				data: mtypes[6],
				color: "#ffcd3c",
			},
			{
				name: "Aramex",
				data: mtypes[8],
				color: "#006eff",
			},
			{
				name: "Bluedart",
				data: mtypes[11],
				color: "#baf7ba",
			},
			// {
			// 	name: "SX",
			// 	data: mtypes[13],
			// 	color: "#64d778",
			// },
			// {
			// 	name: "OX",
			// 	data: mtypes[15],
			// 	color: "#fa5a5a",
			// },
			// {
			// 	name: "SF",
			// 	data: mtypes[16],
			// 	color: "#a064e6",
			// },
			// {
			// 	name: "WO",
			// 	data: mtypes[18],
			// 	color: "#ffcd3c",
			// },
			{
				name: "DTDC Dotzot",
				data: mtypes[7],
				color: "#006eff",
			},
			// {
			// 	name: "EK",
			// 	data: mtypes[19],
			// 	color: "#baf7ba",
			// },
			// {
			// 	name: "TO",
			// 	data: mtypes[10],
			// 	color: "#baf7ba",
			// },
			{
				name: "SpotOn",
				data: mtypes[21],
				color: "#777777",
			},
			{
				name: "Udaan",
				data: mtypes[28],
				color: "#c02028",
			},
			{
				name: "Shypmax",
				data: mtypes[30],
				color: "#006eff",
			}
			]
		},

		zonesType(data) {

			let mtypes = {1:[], 2:[], 3:[], 4:[], 5:[]}, categories = []
			let i = 0
			for(let item in this.data) {
				categories.push(new Date(parseInt(item)).toLocaleDateString('en-IN', { month: '2-digit', day: '2-digit' }))
				for(let m in mtypes) {
					if(this.data[item].zone) {
						mtypes[m][i] = this.data[item].zone[m]?this.data[item].zone[m].total:null
					}
				}
				i++
			}
			this.zoneTypeChart.categories = categories
			this.zoneTypeChart.chartData = [
			{
				name: "Within City",
				data: mtypes[1],
				color: "#64d778",
			},
			{
				name: "Within Zone",
				data: mtypes[2],
				color: "#fa5a5a",
			},
			{
				name: "Between Metro",
				data: mtypes[3],
				color: "#a064e6",
			},
			{
				name: "Rest of India",
				data: mtypes[4],
				color: "#ffcd3c",
			},
			{
				name: "Special",
				data: mtypes[5],
				color: "#006eff",
			}
			]
		},

		ordersDonut() {
			let prepaid = 0, cod = 0, reverse = 0
			for(let item in this.data) {
				if(this.data[item].type) {
					prepaid += this.data[item].type.prepaid?this.data[item].type.prepaid.total:0
					cod += this.data[item].type.cod?this.data[item].type.cod.total:0
					reverse += this.data[item].type.reverse?this.data[item].type.reverse.total:0
				}
			}
			this.orderDonutChart.chartData = [
			{
				name: "Prepaid",
				y: Math.round(prepaid * 100 / (cod+prepaid+reverse)),
				color: "#006eff",
			},
			{
				name: "COD",
				y: Math.round(cod * 100 / (cod+prepaid+reverse)),
				color: "#64d778",
			},
			{
				name: "Reverse",
				y: Math.round(reverse * 100 / (cod+prepaid+reverse)),
				color: "#fa5a5a",
			}
			]
		},

		modesDonut() {
			let mtypes = {1:0, 2:0, 10:0, 8:0, 3:0, 9:0}
			for(let item in this.data) {
				for(let m in mtypes) {
					if(this.data[item].mode) {
						mtypes[m] += this.data[item].mode[m]?this.data[item].mode[m].total:0
					}
				}
			}
			let total = 0
			for(let m in mtypes) {
				total += mtypes[m]
			}
			this.modeDonutChart.chartData = [
			{
				name: "Air",
				y: Math.round(mtypes[1] * 100 / total),
				color: "#64d778",
			},
			{
				name: "Surface-10kg",
				y: Math.round(mtypes[2] * 100 / total),
				color: "#fa5a5a",
			},
			{
				name: "Surface-5kg",
				y: Math.round(mtypes[10] * 100 / total),
				color: "#a064e6",
			},
			{
				name: "Lite-1kg",
				y: Math.round(mtypes[8] * 100 / total),
				color: "#ffcd3c",
			},
			{
				name: "Lite-2kg",
				y: Math.round(mtypes[3] * 100 / total),
				color: "#006eff",
			},
			{
				name: "Lite-0.5kg",
				y: Math.round(mtypes[9] * 100 / total),
				color: "#baf7ba",
			}
			]
		},

		statusDonutChart() {
			let mtypes = {0:0, 1:0, 2:0, 3:0, 4:0, 5:0, 6:0, 7:0, 8:0, 9:0, 10:0}
			for(let item in this.data) {
				for(let m in mtypes) {
					if(this.data[item].status) {
						mtypes[m] += this.data[item].status[m]?this.data[item].status[m].total:0
					}
				}
			}
			let total = 0
			for(let m in mtypes) {
				total += mtypes[m]
			}
			this.statusDonutData.chartData = [
			{
				name: "Booked",
				y: mtypes[0],
				color: "#89e290",
			},
			{
				name: "Picked Up",
				y: mtypes[1],
				color: "#006eff",
			},
			{
				name: "In Transit",
				y: mtypes[2],
				color: "#a064e6",
			},
			{
				name: "Delayed",
				y: mtypes[3],
				color: "#ffcd3c",
			},
			{
				name: "Exception",
				y: mtypes[4],
				color: "#ffa500",
			},
			{
				name: "Out for Delivery",
				y: mtypes[5],
				color: "#3ca53c",
			},
			{
				name: "RTO-Notified",
				y: mtypes[6],
				color: "#ff8246",
			},
			{
				name: "Delivered",
				y: mtypes[7],
				color: "#67da67",
			},
			{
				name: "Lost",
				y: mtypes[8],
				color: "#f79595",
			},
			{
				name: "Out for Pickup",
				y: mtypes[9],
				color: "#0e57da",
			},
			{
				name: "Cancelled",
				y: mtypes[10],
				color: "#fa5a5a",
			}
			]
		},


		carriersByModesBarChart() {

			let carriers = [2,3,4,11,6,8,30,7,28,21];
			let modes = {1:[], 2:[], 3:[], 8:[], 9:[], 10:[], 20:[]}

			for(let item in this.data) {
				let i = 0
				for(let c of carriers) {
					if(this.data[item].carrierID && this.data[item].carrierID[c]) {
						for(let m in modes) {
							if(this.data[item].carrierID[c].mode && this.data[item].carrierID[c].mode[m]) {
								if(!modes[m][i]) {
									modes[m][i] = null
								}

								modes[m][i] += this.data[item].carrierID[c].mode[m] ? this.data[item].carrierID[c].mode[m] : null
							}
						}
					}
					i++
				}
			}
			modes = JSON.parse(JSON.stringify(modes).replaceAll(null,null))
			// this.carriersByModesBarData.categories = ["DL","EE","FX","XB","AR","BD","SX","OX","SF","WO","DZ","EK","TO","SO","UD","SY"];
			this.carriersByModesBarData.categories = ['Delhivery', 'EcomExpress', 'Fedex', 'Bluedart', 'Xpressbees', 'Aramex', 'Shypmax', 'DTDC Dotzot', 'Udaan', 'SpotOn']
			this.carriersByModesBarData.chartData = [
			{
				name: "Surface-30kg",
				data: modes[20],
				color: "#ff8246"
			},
			{
				name: "Surface-10kg",
				data: modes[2],
				color: "#fa5a5a",
			},
			{
				name: "Surface-5kg",
				data: modes[10],
				color: "#a064e6",
			},
			{
				name: "Lite-2kg",
				data: modes[3],
				color: "#006eff",
			},
			{
				name: "Lite-1kg",
				data: modes[8],
				color: "#ffcd3c",
			},
			{
				name: "Lite-0.5kg",
				data: modes[9],
				color: "#baf7ba",
			},
			{
				name: "Air",
				data: modes[1],
				color: "#64d778",
			}
			]
		},

		zonesByCarriersBarChart() {
			// let carriers = {2:[],3:[],4:[],6:[],8:[],11:[],13:[],15:[],16:[],18:[],7:[],19:[],20:[],21:[],28:[],30:[]}
			let carriers = {2:[],3:[],4:[],11:[],6:[],8:[],30:[],7:[],28:[],21:[]}
			let zones = [0,1,2,3,4,5]
			for(let item in this.data) {
				let i = 0
				for(let z in zones) {
					if(this.data[item].zone && this.data[item].zone[i]) {
						for(let c in carriers) {
							if(this.data[item].zone[i].carrierID && this.data[item].zone[i].carrierID[c]) {
								if(!carriers[c][i]) {
									carriers[c][i] = 0
								}
								carriers[c][i] += parseInt(this.data[item].zone[i].carrierID[c]) || 0
							}
						}
					}
					i++
				}
			}
			carriers = JSON.parse(JSON.stringify(carriers).replaceAll(null,null))
			this.zonesByCarriersBarData.categories = ['Within City', 'Within Zone', 'Between Metro', 'Rest of India', 'Special']
			this.zonesByCarriersBarData.chartData = [
			{
				name: "Delhivery",
				data: carriers[2].splice(1,5),
				color: "#EE3C26",
			},
			{
				name: "EcomExpress",
				data: carriers[3].splice(1,5),
				color: "#941608",
			},
			{
				name: "Fedex",
				data: carriers[4].splice(1,5),
				color: "#00CC00",
			},
			{
				name: "Xpressbees",
				data: carriers[6].splice(1,5),
				color: "#E3A206",
			},
			{
				name: "Aramex",
				data: carriers[8].splice(1,5),
				color: "#EE0000",
			},
			{
				name: "Bluedart",
				data: carriers[11].splice(1,5),
				color: "#00A758",
			},
			{
				name: "SWA",
				data: carriers && carriers[29] ? carriers[29].splice(1,5) : '',
				color: "#2e4bb1",
			},
			{
				name: "DTDC-Plus",
				data: carriers && carriers[33] ? carriers[33].splice(1,5) : '',
				color: "#0a3073",
			},
			// {
			// 	name: "SX",
			// 	data: carriers[13].splice(1,5),
			// 	color: "#2F780A",
			// },
			// {
			// 	name: "OX",
			// 	data: carriers[15].splice(1,5),
			// 	color: "#FBF22B",
			// },
			// {
			// 	name: "SF",
			// 	data: carriers[16].splice(1,5),
			// 	color: "#F05926",
			// },
			// {
			// 	name: "WO",
			// 	data: carriers[18].splice(1,5),
			// 	color: "#99F08C",
			// },
			{
				name: "DTDC Dotzot",
				data: carriers[7].splice(1,5),
				color: "#0D237D",
			},
			// {
			// 	name: "EK",
			// 	data: carriers[19].splice(1,5),
			// 	color: "#2A75C9",
			// },
			// {
			// 	name: "TO",
			// 	data: carriers[20].splice(1,5),
			// 	color: "#B25110",
			// },
			{
				name: "SpotOn",
				data: carriers[21].splice(1,5),
				color: "#777777",
			},
			{
				name: "Udaan",
				data: carriers[28].splice(1,5),
				color: "#c02028",
			},
			{
				name: "Shypmax",
				data: carriers[30].splice(1,5),
				color: "#006eff",
			}
			]
		},


		async getItems(dateRange) {
			this.isBusy = true
			let firstDay = null, lastDay = null
			const date = new Date();
			if(dateRange == '7 Days') {
				firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6)
				lastDay = new Date(date.getTime())
			}else if(dateRange == '30 Days') {
				firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 29)
				lastDay = new Date(date.getTime())
			}else if(dateRange == 'Last Month') {
				firstDay = new Date(date.getFullYear(), date.getMonth() - 1);
				lastDay = new Date(date.getFullYear(), date.getMonth(), 1);
			}else if(dateRange == 'This Quarter') {
				const ql = [4,1,2,3];
				const q = ql[Math.floor(date.getMonth() / 3)];
				const qRanges = {
					"4": {
						"start": new Date(date.getFullYear(), 0, 1),
						"end": new Date(date.getFullYear(), 2, 31)
					},
					"1": {
						"start": new Date(date.getFullYear(), 3, 1),
						"end": new Date(date.getFullYear(), 5, 30)
					},
					"2": {
						"start": new Date(date.getFullYear(), 6, 1),
						"end": new Date(date.getFullYear(), 8, 30)
					},
					"3": {
						"start": new Date(date.getFullYear(), 9, 1),
						"end": new Date(date.getFullYear(), 11, 31)
					}
				}


				firstDay = new Date(qRanges[q].start);
				lastDay = new Date(qRanges[q].end);
			}  else {
				firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
				lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
			}

			this.firstDay = firstDay.getTime()
			this.lastDay = lastDay.getTime()

			const res = await this.axios.get(`/v3/dashboard/graphround/v2?from=${firstDay.getTime()}&to=${lastDay.getTime()}`)
			
			if(dateRange && res.data.success === true) {
				this.data = res.data.data
			}else{
				this.$store.commit('setCharts', res.data.data)	
			}
			this.isBusy = false
			this.allCharts()
		},
	},
	computed: {
		carriers() {
			return this.$store.getters.carriers;
		}
	},
	watch: {
		dateRange: function(newValue) {
			this.getItems(newValue)
		}
	}
};
</script>

<style lang="scss">
.date-wrap {
	width: rem(230px);
	.xmx-input {
		padding: rem(14px) rem(28px) rem(14px) rem(16px);
	}
	.xmx-icon-calendar {
		display: block;
		right: rem(16px);
	}
	.xmx-icon-clear {
		right: rem(16px);
	}
}

@include media-breakpoint-down(sm) {
	.btn-download {
		position: fixed;
		top: rem(86px);
		z-index: 99;
	}
	.date-wrap {
		width: 100%;
	}
	.xmx-datepicker-main {
		top: 0 !important;
	}
	.xmx-datepicker-footer {
		display: none;
	}
}
.chart_height {
	height: 400px;
	display: flex;
}
.dateRange {
	.multiselect__tags, .multiselect__single {
		background: #fff!important;
	}
}

.table .table th, .table .table td {
	border-top: 0;
}
</style>